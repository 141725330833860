<template>
  <div>
    <rotate-text>Hey there!</rotate-text>

    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="hello-intro">
          <div class="row">
            <div class="col-lg-9 col-md-9">
              <h2 class="hello-header" data-aos="fade-up">
                <div class="my-2">Hello,</div>I'm Benjamin!
              </h2>
              <p class="hello-sub">Benjamin Iduwe.</p>
              <p class="hello-text" data-aos="fade-up" data-aos-delay="200">
                A Software Engineer based in Lagos, Nigeria. I am passionate about creating robust, scalable, and test-driven web
                applications. I enjoy writing clean codes, listening to
                music and solving web-related problems. I have worked on over
                <router-link to="/projects">
                  <a class="font-weight-bold pr-1">25 projects</a>
                </router-link>as a lead developer and a team player. Some of my open source
                projects are available on
                <a
                  target="_blank"
                  :href="`http://github.com/${this.$config.socials.github}`"
                  class="font-weight-bold"
                >Github</a>. I am available for contracts and remote full-time roles. Got a
                gig for me?
                <router-link to="/contact">
                  <a class="font-weight-bold">Contact me</a>
                </router-link>.
              </p>
            </div>
          </div>
        </div>
        <next-page path="/about" title="About Me" content="Know Something More"></next-page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  computed: {
    title() {
      return `Home - ${this.$config.name}`;
    },
  },
};
</script>

<style></style>
